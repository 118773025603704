/*
 * File: metrics.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:09 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Exports
 */

exports.handlePageView = (source, entityId, localeId) => {

	// Record view
	const params = { source, entity_id: entityId, locale_id: localeId };
	Parse.Cloud.run('recordViewWithParameters', params);
};

exports.recordAction = (id, data, localeId) => {

	// Record view
	const params = { id, data, locale_id: localeId };
	Parse.Cloud.run('recordActionWithParameters', params);
};
