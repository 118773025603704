/*
 * File: reaction-community-modal-handler.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Utilities
const utilitiesModule = require('../../../modules/utilities');


/**
 * Handlers
 */

exports.showModal = () => {

	// Handle open modal
	utilitiesModule.showModalWithId('reaction-community-modal');
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Handle click on continue button
	$('#reaction-modal-continue-button').click(() => {

		// Handle close modal
		utilitiesModule.hideModalWithId('reaction-community-modal');
	});
};


/**
 * Init
 */

exports.initialize = async () => {

	// Create action handlers
	createActionHandlers();
};
