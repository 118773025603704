module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"reaction-badge ")
    ; __append(escapeFn( reaction.position ))
    ; __append(" ")
    ; __append(escapeFn( reaction.icon ))
    ; __append(" ")
    ;  if (reaction.shouldAnimate === true) { 
    ; __append(" reaction-add-bounce ")
    ;  } 
    ; __append("\"><div class=\"legend-reaction\"><span>")
    ; __append(escapeFn( reaction.legend ))
    ; __append("<i class=\"far fa-times-circle\" name=\"reaction-remove-selector\"></i></span></div></div>")
  }
  return __output;

}