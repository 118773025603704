/*
 * File: navigation.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:09 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Global Variables
 */

const dropdownElementHeights = {};


/**
 * Handlers
 */

function handleScrollHeader() {

	// Get parameters
	const scrollPos = $(document).scrollTop();
	const topComponent = $('#header-top-bar-component');
	const topComponentHeight = (topComponent.length) ? topComponent.outerHeight() : 0;
	const scrollingOffset = 70 + topComponentHeight;

	// Select scrolling headers
	$('.scrolling-header-container').each(function () {
		if (scrollPos < topComponentHeight) {
			$(this).children('.header').css('position', 'absolute');
			$(this).children('.header').css('top', '');
		} else {
			$(this).children('.header').css('top', '0px');
			$(this).children('.header').css('position', 'fixed');
		}
		if (scrollPos > scrollingOffset) {
			if (!($(this).hasClass('scrolling-header'))) {
				$(this).addClass('scrolling-header');
			}
		} else if ($(this).hasClass('scrolling-header')) {
			$(this).removeClass('scrolling-header');
		}
	});
}


/**
 * Action Handlers
 */

// Enable Navigation Bar
$('a').click(function () {
	if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-app-menu') {
		$('#dropdown-menu').toggleClass('show');
		$('#menu-arrow-open').toggleClass('hide');
		$('#menu-arrow-close').toggleClass('hide');
		return false;
	}
	if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-legal-menu') {
		$('#legal-dropdown-menu').toggleClass('show');
		$('#legal-menu-arrow-open').toggleClass('hide');
		$('#legal-menu-arrow-close').toggleClass('hide');
		return false;
	}
	return undefined;
});

// Check Navigation Bar Display
if ($('#dropdown-menu').length) {
	$(window).resize(() => {
		const breakpoint = ($('#navigation-container').hasClass('header-mobile-additional-breakpoint-show')) ? 1024 : 856;
		if ($(window).width() >= breakpoint) {
			if ($('#dropdown-menu').hasClass('show')) {
				$('#dropdown-menu').toggleClass('show');
				$('#menu-arrow-open').toggleClass('hide');
				$('#menu-arrow-close').toggleClass('hide');
			}
		}
	});
}

// Handle scroll components
if ($('.scrolling-header-container').length) {
	$(document).ready(() => {

		// Handle scroll header
		handleScrollHeader();
	});
	$(document).scroll(() => {

		// Handle scroll header
		handleScrollHeader();
	});
}

// Initialize dropdown values
$(window).on('load', () => {
	$('.navigation-dropdown').each(function () {
		$(this).css('height', 'auto');
		dropdownElementHeights[$(this).attr('id')] = $(this).height();
		$(this).css('height', '0px');
	});
});

// Handle dropdown actions
$('.show-navigation-dropdown').on({
	mouseenter() {

		// Get dropdown component
		const dropdown = $(this).find('.navigation-dropdown');

		// Animate element
		const currentHeight = dropdown.height();
		const autoHeight = dropdownElementHeights[dropdown.attr('id')];
		dropdown.height(currentHeight).animate({ height: autoHeight }, 40);
	},
	mouseleave() {

		// Get dropdown component
		const dropdown = $(this).find('.navigation-dropdown');

		// Animate element
		const currentHeight = dropdown.height();
		dropdown.height(currentHeight).animate({ height: 0 }, 40);
	}
});

// Handle locale click selector
$(document).on('click', '[name="locale-selector-language"]', function (e) {
	e.stopPropagation();

	// Get adjacent dropdown
	const dropdown = $(this).next('.locale-selector-options');

	// Toggle dropdown visibility
	dropdown.toggleClass('visible');
});

// Handle document click selector
$(document).click(() => {

	// Hide locale dropdown
	$('.locale-selector-options').removeClass('visible');
});
