/*
 * File: general.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: July 25, 2024 at 8:47 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// CSS
require('foundation-sites/dist/css/foundation.css');
require('../../css/app.css');

// Modules
window.Parse = require('parse');
const jQuery = require('jquery');
require('foundation-sites');

// Utilities
const { reduceToBaseURL } = require('./utilities');
const {
	handlePerformanceConsentUpdate,
	handleAnalyticsConsentUpdate,
	handleTargetingConsentUpdate
} = require('./cookies');

// Constants
const {
	CONSENT_COOKIE_EXPIRATION,
	CONSENT_ACTION_TAKEN_COOKIE,
	CONSENT_PERFORMANCE_ENABLED_COOKIE,
	CONSENT_ANALYTICS_ENABLED_COOKIE,
	CONSENT_TARGETING_ENABLED_COOKIE
} = require('../../../constants/general.constants');

// Globals
window.$ = jQuery;
window.jQuery = jQuery;


/**
 * State
 */

let globalState = null;


/**
 * Init
 */

// Initialize Foundation
$(document).foundation();

// Show escape button
$('.leave-page-fab.script-enabled').show();

// Activate post-load features
$(document).ready(() => {
	$('body').removeClass('preload');
});

// Get global data
if ($('#data-source').length) {
	globalState = JSON.parse(JSON.stringify($('#data-source').data('source')));
	setTimeout(() => {
		$('#data-source').remove();
	}, 1000);
}

// Create dynamic server URL
let { SERVER_URL } = process.env;
if (process.env.ENV !== 'development' && process.env.IS_CORE_APP === 'false') {
	SERVER_URL = `${window.location.protocol}//${window.location.host}${process.env.PARSE_MOUNT}`;
}

// Initialize Parse
Parse.initialize(process.env.APP_ID);
Parse.serverURL = SERVER_URL;

// Destroy user session - TODO - V3 - REMOVE
try { Parse.User.logOut(); } catch (err) {}


/**
 * Exports
 */

exports.handleLeavePage = function () {
	document.body.innerHTML = '';
	window.open(globalState.state.entity.escape.new_tab_url, '_newtab');
	window.location.replace(globalState.state.entity.escape.replace_url);
};


/**
 * Handlers
 */

// Remove hash from URL
$(document).ready(() => {
	const urlString = window.location.toString();
	if (urlString.indexOf('#') !== -1) {
		const newurlString = urlString.split('#')[0];
		if (urlString.split('#')[1] === 'top' || urlString.split('#')[1] === '') {
			window.history.pushState(null, '', newurlString);
		}
	}
});

// Handle escape button logic
$('.leave-page-fab.script-enabled').click(() => {
	exports.handleLeavePage();
});

// Enable smooth scroll
const $root = $('html, body');
$('a').click(function () {
	if ($(this).attr('href') && $(this).attr('href').indexOf('#') > -1 && $(this).attr('href') !== '#') {

		// Create base urls
		const path = reduceToBaseURL(window.location.pathname);
		const comparePath = reduceToBaseURL($(this).attr('href').split('#')[0]);

		// Compare and route path
		if (path === comparePath) {
			const tag = $(this).attr('href').split('#').pop();
			if (tag !== '') {
				$root.animate({
					scrollTop: $(`#${tag}`).offset().top
				}, 500);
				return false;
			}
		} else {
			window.location = $(this).attr('href');
		}
	}
	return undefined;
});

// Handle get component state
exports.getComponentState = () => globalState;


/**
 * Cookie Bar Handlers
 */

// Handle positioning of FAB
const handleFABPosition = () => {
	if ($('#cookie-consent-bar').length && !$('#cookie-consent-bar').is(':hidden')) {
		const offset = `${$('#cookie-consent-bar').outerHeight() + 20}px`;
		$('.leave-page-fab').each(function () {
			$(this).css('bottom', offset);
		});
	} else {
		$('.leave-page-fab').each(function () {
			$(this).css('bottom', '20px');
		});
	}
};

// Initialize position of FAB
if ($('#cookie-consent-bar').length) {
	$(document).ready(() => {

		// Handle FAB position
		handleFABPosition();

		// Handle position on window resize
		$(window).resize(() => {
			handleFABPosition();
		});
	});
}

// Handle cookie bar accept action
$('#cookie-bar-accept-all-action').click(() => {

	// Set all cookies
	const expires = (new Date(Date.now() + CONSENT_COOKIE_EXPIRATION)).toUTCString();
	const entityDomain = (globalState.state.is_core) ? 'ourwave.org' : globalState.state.entity.full_domain;
	const cookieDomain = (process.env.ENV === 'development') ? '' : `domain=${entityDomain};`;
	document.cookie = `${CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/; ${cookieDomain}`;
	document.cookie = `${CONSENT_PERFORMANCE_ENABLED_COOKIE}=true; expires=${expires}; path=/; ${cookieDomain}`;
	document.cookie = `${CONSENT_ANALYTICS_ENABLED_COOKIE}=true; expires=${expires}; path=/; ${cookieDomain}`;
	document.cookie = `${CONSENT_TARGETING_ENABLED_COOKIE}=true; expires=${expires}; path=/; ${cookieDomain}`;

	// Hide cookie bar
	const value = `-${$('#cookie-consent-bar').outerHeight()}px`;
	$('#cookie-consent-bar').animate({ bottom: value }, 300, function () {
		$(this).hide(400, () => {
			handleFABPosition();
		});
	});

	// Handle consent updates
	handlePerformanceConsentUpdate(true, globalState);
	handleAnalyticsConsentUpdate(true, globalState);
	handleTargetingConsentUpdate(true, globalState);
});

// Handle cookie bar customize settings action
$('#cookie-bar-customize-action').click(() => {

	// Set action taken cookie
	const expires = (new Date(Date.now() + CONSENT_COOKIE_EXPIRATION)).toUTCString();
	const entityDomain = (globalState.state.is_core) ? 'ourwave.org' : globalState.state.entity.full_domain;
	const cookieDomain = (process.env.ENV === 'development') ? '' : `domain=${entityDomain};`;
	document.cookie = `${CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/; ${cookieDomain}`;

	// Move to cookie customize
	const domain = (globalState.state.is_core) ? 'https://www.ourwave.org' : '';
	window.location = `${domain}${globalState.locale.locale_path}/cookie-statement#settings`;
});
