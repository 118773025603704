/*
 * File: stories-renderer.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Utilities
const utilities = require('../modules/utilities');

// Constants
const { OBFUSCATE_REGEX, INTERNAL_CONTENT_TYPES } = require('../../../constants/general.constants');


/**
 * Handlers
 */

exports.renderStories = async (stories, localeObj, stateObj, reactionSchema, updateSchema, compilers) => {

	// Get compilers
	const { storyCardCompiler, mantraCardCompiler } = compilers;

	// Get templates
	const storyCard = storyCardCompiler();
	const mantraCard = mantraCardCompiler();

	// Initialize story elements
	let elements = '';

	// Build elements
	for (let i = 0; i < stories.length; i += 1) {
		const item = stories[i];

		// Render for type
		if (item.internal_content_type === INTERNAL_CONTENT_TYPES.STORY) {

			// Get story
			const story = item;

			// Get parameters
			const title = story.title == null ? `#${story.number}` : story.title;
			const linkComponent = story.slug == null ? story.story_id : story.slug;
			const allowedSources = story.allowed_preview_sources;
			let content = '';
			if (allowedSources.story === true && story.excerpt != null) {
				content = story.excerpt;
			}
			if (allowedSources.hope_message === true && story.hope_message != null && story.hope_message !== '') {
				content = utilities.generateExcerptFromContent(story.hope_message);
			} else if (allowedSources.healing_message === true && story.healing_message != null && story.healing_message !== '') {
				content = utilities.generateExcerptFromContent(story.healing_message);
			}

			// Add reactions to stories
			if (story.reactions == null) {
				const reactionSchemaObj = utilities.getLocalReactionSchema(reactionSchema);
				const reactionArray = reactionSchemaObj[story.story_id] != null ? reactionSchemaObj[story.story_id] : [];
				story.reactions = reactionArray;
			}

			// Add update parameters to stories
			try {
				const updateSchemaObj = utilities.getLocalUpdateSchema(updateSchema);
				const updateVisibilityArray = updateSchemaObj[story.story_id] != null ? updateSchemaObj[story.story_id] : [];
				for (let j = 0; j < story.updates.length; j += 1) {
					story.updates[j].viewed = updateVisibilityArray.includes(story.updates[j].update_id);
				}
			} catch (e) {}

			// Format content
			content = content.replace(/\n/g, '{~br~}');

			// Format reactions
			const reactions = utilities.formatReactionArray(story.reactions, localeObj, false);

			// Recursively render templates
			const html = storyCard({
				id: story.story_id,
				trigger_message: story.trigger_message,
				is_triggering: story.is_triggering,
				title,
				content,
				media: story.media,
				linkComponent,
				reactions,
				partner_entity: story.partner_entity,
				locale: localeObj,
				state: stateObj,
				update_unread_count: story.updates.map((update) => (update.viewed === true ? 0 : 1)).reduce((a, b) => a + b, 0)
			});

			// Append element
			elements += html;

		} else if (item.internal_content_type === INTERNAL_CONTENT_TYPES.MANTRA) {

			// Get mantra
			const mantra = item;

			// Recursively render templates
			const html = mantraCard({
				id: mantra.id,
				locale: localeObj,
				state: stateObj,
				content: mantra.content,
				attribution: mantra.attribution,
				dimensions: mantra.dimensions
			});

			// Append element
			elements += html;
		}
	}

	// Format content
	elements = elements.replace(OBFUSCATE_REGEX, (str) => {
		const content = str.slice(2, -2);
		if (content === '') {
			return '<span class=\'obfuscate\'>********</span>';
		} if (content === 'br') {
			return '<br>';
		}
		return `<span class='obfuscate content'>${content}</span>`;
	});

	// Return content
	return elements;
};
