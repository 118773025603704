module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grid-item column large-4-col medium-6-col small-12-col grounding-container ")
    ;  if (data.show_overlay) { 
    ; __append(" overlay ")
    ;  } 
    ; __append("\" id=\"grounding-card\"><div class=\"story-card\"><div id=\"grounding-container\"> ")
    ; (function(){
    ; __append("<!--\n * File: grounding_initial.ejs\n * Project: our-wave-stories-app\n * \n * Created by Brendan Michaelsen on August 9, 2024 at 11:01 AM\n * Copyright © 2024 Our Wave, Inc. All rights reserved.\n * \n * Last Modified: August 9, 2024 at 11:09 AM\n * Modified By: Brendan Michaelsen\n-->\n\n<div class=\"grounding-card\">\n\n    <!-- Content -->\n	")
    ;  if (state.entity.content != null && state.entity.content.grounding_exercise != null && state.entity.content.grounding_exercise.grounding_initial != null && state.entity.content.grounding_exercise.grounding_initial.title != null) { 
    ; __append("\n		<h2>")
    ; __append( state.entity.content.grounding_exercise.grounding_initial.title )
    ; __append("</h2>\n	")
    ;  } else { 
    ; __append("\n		<h2>")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_TITLE )
    ; __append("</h2>\n	")
    ;  } 
    ; __append("\n	")
    ;  if (state.entity.content != null && state.entity.content.grounding_exercise != null && state.entity.content.grounding_exercise.grounding_initial != null && state.entity.content.grounding_exercise.grounding_initial.content != null) { 
    ; __append("\n		<p>")
    ; __append( state.entity.content.grounding_exercise.grounding_initial.content )
    ; __append("</p>\n	")
    ;  } else { 
    ; __append("\n		<p>")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_CONTENT )
    ; __append("</p>\n	")
    ;  } 
    ; __append("\n\n    <!-- Actions -->\n    <button class=\"dark-blue-cta solid animate\" id=\"start-grounding-button\">")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_START_BUTTON )
    ; __append("</button>\n    <button class=\"secondary-button animate\" id=\"skip-grounding-button\">")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_INITIAL_SKIP_BUTTON )
    ; __append("</button>\n</div>\n")
    ; }).call(this)
    ; __append(" </div></div></div>")
  }
  return __output;

}