/*
 * File: analytics.js
 * Project: our-wave-stories-app
 *
 * Created by Brendan Michaelsen on December 30, 2021 at 4:28 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 23, 2024 at 6:21 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Google Analytics
 */

exports.recordGAEvent = (hitType, category, action, label) => {
	if (process.env.ENV === 'production') {
		try {
			const tracker = ga.getAll()[0];
			if (tracker) tracker.send(hitType, category, action, label);
		} catch (error) {}
	}
};


/**
 * Hotjar
 */

exports.recordHotjarState = (type, uri) => {
	if (process.env.ENV === 'production') {
		try {
			hj(type, uri);
		} catch (error) {}
	}
};
