module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"grounding-card\"><h2>")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_TITLE )
    ; __append("</h2> ")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_5_CONTENT )
    ; __append(" <button class=\"dark-blue-cta solid animate\" id=\"finish-grounding-button\">")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_READY_BUTTON )
    ; __append("</button> <button class=\"secondary-button animate\" id=\"another-grounding-button\">")
    ; __append( locale.content.PARTIAL_GROUNDING_EXERCISE_ANOTHER_BUTTON )
    ; __append("</button></div>")
  }
  return __output;

}